import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';

import '../styles/index.sass';

const TemplateWrapper = ({ children }) => {
    const [isMenuOpen, setShowMenu] = useState(false);
    return (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    datoCmsSite {
                        name
                        globalSeo {
                            siteName
                            fallbackSeo {
                                image {
                                    fluid {
                                        src
                                    }
                                }
                            }
                        }
                        faviconMetaTags {
                            ...GatsbyDatoCmsFaviconMetaTags
                        }
                    }
                    datoCmsHome {
                        seoMetaTags {
                            ...GatsbyDatoCmsSeoMetaTags
                        }
                        name
                        introTextNode {
                            childMarkdownRemark {
                                html
                            }
                        }
                        copyright
                    }
                    allDatoCmsSocialProfile(
                        sort: { fields: [position], order: ASC }
                    ) {
                        edges {
                            node {
                                profileType
                                url
                            }
                        }
                    }
                }
            `}
            render={data => (
                <div className={`container ${isMenuOpen ? 'is-open' : ''}`}>
                    <HelmetDatoCms
                        favicon={data.datoCmsSite.faviconMetaTags}
                        seo={data.datoCmsHome.seoMetaTags}
                    />

                    {/* Sidebar */}
                    <div className="container__sidebar">
                        <div className="sidebar">
                            {/* My profile picture */}
                            <Link to="/">
                                <img
                                    className="sidebar__img"
                                    alt={data.datoCmsSite.name}
                                    key={
                                        data.datoCmsSite.globalSeo.fallbackSeo
                                            .image.fluid.src
                                    }
                                    src={
                                        data.datoCmsSite.globalSeo.fallbackSeo
                                            .image.fluid.src
                                    }
                                />
                            </Link>

                            <h6 className="sidebar__title">
                                <Link to="/">{data.datoCmsHome.name}</Link>
                            </h6>
                            <div
                                className="sidebar__intro"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        data.datoCmsHome.introTextNode
                                            .childMarkdownRemark.html,
                                }}
                            />
                            <ul className="sidebar__menu">
                                {/* <li>
                  <Link to="/">Home</Link>
                </li> */}
                                {/* <li>
                  <Link to="/about">About</Link>
                </li> */}
                            </ul>
                            <p className="sidebar__social">
                                {data.allDatoCmsSocialProfile.edges.map(
                                    ({ node: profile }) => (
                                        <a
                                            key={profile.profileType}
                                            href={profile.url}
                                            target="blank"
                                            className={`social social--${profile.profileType.toLowerCase()}`}
                                        >
                                            {' '}
                                        </a>
                                    )
                                )}
                            </p>
                            <div className="sidebar__copyright">
                                {data.datoCmsHome.copyright}
                            </div>
                        </div>
                    </div>

                    {/* Body */}
                    <div className="container__body" >
                        {/* Overlay above the body */}
                        {
                            isMenuOpen ? <div className="container__body-overlay" onClick={() => { setShowMenu(false) }}></div> : null
                        }

                        {/* Mobile design */}
                        <div style={{ pointerEvents: isMenuOpen ? 'none' : 'all' }}>
                            <div className="container__mobile-header">
                                <div className="mobile-header">
                                    {/* Mobile Menu Icon */}
                                    <div className="mobile-header__menu">
                                        <a
                                            href="#"
                                            onClick={e => {
                                                e.preventDefault();
                                                setShowMenu(!isMenuOpen);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {children}
                        </div>

                    </div>
                </div>
            )}
        />
    );
};

TemplateWrapper.propTypes = {
    children: PropTypes.object,
};

export default TemplateWrapper;
